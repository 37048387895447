<template>
  <b-row>
    <b-col cols="12">
      <b-card title="">
        <b-overlay
            variant="white"
            :show="showLoading"
            spinner-variant="primary"
            blur="0"
            opacity=".75"
            rounded="sm"
        >
          <div class="ml-1 mb-2">
            <b-row>
              <b-col cols="12" md="9"
                     class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
              >
                <button class="btn btn-primary" @click="exportToExcel">Exportar a Excel</button>
              </b-col>
            </b-row>
          </div>

          <b-table
              ref="refUserListTable"
              class="position-relative table-size-list"
              :items="children_installations"
              responsive
              sticky-header
              striped
              :fields="columns"
              primary-key="id"
              show-empty
              empty-text="No hay registros"
          >
            <template #cell(segment)="data">
              {{ data.item.master_installation.segment ? data.item.master_installation.segment.segment : null }}
            </template>

            <template #cell(client_cif)="data">
              {{ data.item.master_installation.client_cif }}
            </template>

            <template #cell(installation_code)="data">
              {{ data.item.gei_code }}
            </template>

            <template #cell(active)="data">
              <b-badge :variant="data.item.active_installation === 1 ? 'success' : 'danger'">
                {{ data.item.active_installation === 1 ? 'Si' : 'No' }}
              </b-badge>
            </template>

            <template #cell(draft)="data">
              <b-badge :variant="data.item.draft === 1 ? 'success' : 'danger'">
                {{ data.item.draft === 1 ? 'Si' : 'No' }}
              </b-badge>
            </template>

            <template #cell(client)="data">
              {{ data.item.master_installation.client }}
            </template>

            <template #cell(responsible)="data">
              {{ data.item.master_installation.responsible ? data.item.master_installation.responsible.name : null }}
            </template>

            <template #cell(figure)="data">
              {{ figures[data.item.master_installation.figure_id - 1] ? figures[data.item.master_installation.figure_id - 1].name : null }}
            </template>

            <template #cell(country)="data">
              {{ data.item.master_installation.province_country
                ? data.item.master_installation.province_country.country
                : null }}
            </template>

            <template #cell(address)="data">
              {{ data.item.master_installation.address }}
            </template>

            <template #cell(installation_type_id)="data">
              {{ data.item.typology ? data.item.typology.name : null }}
            </template>

            <template #cell(province)="data">
              {{ data.item.master_installation.province_country
                ? data.item.master_installation.province_country.province
                : null }}
            </template>

            <template #cell(city)="data">
              {{ data.item.master_installation.city }}
            </template>

            <template #cell(installation_type)="data">
              {{ data.item.installation_type ? data.item.installation_type.type : null }}
            </template>

            <template #cell(date_start)="data">
              {{ data.item.start_date ? helpers.formatDate(data.item.start_date) : null }}
            </template>

            <template #cell(date_end)="data">
              {{ data.item.end_date ? helpers.formatDate(data.item.end_date) : null }}
            </template>

            <template #cell(period_description)="data">
              {{ data.item.period_description }}
            </template>

            <template #cell(maintainer)="data">
              {{ data.item.provider_contract_e_e[0] ?
                (data.item.provider_contract_e_e[0].provider ? data.item.provider_contract_e_e[0].provider.provider : null)
                : null }}
            </template>

            <template #cell(level_1)="data">
              {{ data.item.master_installation.level_1 ? data.item.master_installation.level_1.name : null }}
            </template>

            <template #cell(level_2)="data">
              {{ data.item.master_installation.level_2 ? data.item.master_installation.level_2.name : null }}
            </template>

            <template #cell(level_3)="data">
              {{ data.item.master_installation.level_3 ? data.item.master_installation.level_3.name : null }}
            </template>

            <template #cell(level_4)="data">
              {{ data.item.master_installation.level_4 ? data.item.master_installation.level_4.name : null }}
            </template>

            <template #cell(opsva_territory)="data">
              {{ data.item.master_installation.opsva_territory ? data.item.master_installation.opsva_territory.name : null }}
            </template>

            <template #cell(client_type)="data">
              {{ data.item.master_installation.client_type ? data.item.master_installation.client_type.type : null }}
            </template>

            <template #cell(client_contact_person)="data">
              {{ data.item.master_installation.client_contact_person }}
            </template>

            <template #cell(client_phone)="data">
              {{ data.item.master_installation.client_phone }}
            </template>

            <template #cell(client_email)="data">
              {{ data.item.master_installation.client_email }}
            </template>

            <template #cell(company)="data">
              {{ data.item.master_installation.company ? data.item.master_installation.company.name : null }}
            </template>
          </b-table>
        </b-overlay>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import {BRow, BCol, BTable, BCard, BOverlay, BBadge} from 'bootstrap-vue'
import {mapState} from "vuex"
import helpers from "@/resources/helpers/helpers"
import ExcelJS from "exceljs"

export default {
  name: "ListView",
  components: {
    BRow,
    BCol,
    BTable,
    BCard,
    BOverlay,
    BBadge,
  },
  data() {
    return {

      columns: [
        {key: 'client_cif', label: 'CIF', sortable: false},
        {key: 'installation_code', label: 'CODIGO_GEI_1', sortable: false},
        {key: 'active', label: 'InstalaciónActiva', sortable: false},
        {key: 'draft', label: 'InstalaciónBorrador', sortable: false},
        {key: 'client', label: 'Referencia', sortable: false},
        {key: 'responsible', label: 'Responsable', sortable: false},
        {key: 'figure', label: 'Figura RD171', sortable: false},
        {key: 'country', label: 'Pais', sortable: false},
        {key: 'address', label: 'Dirección Gei', sortable: false},
        {key: 'province', label: 'Provincia/Zona', sortable: false},
        {key: 'city', label: 'Población', sortable: false},
        {key: 'installation_type_id', label: 'Tipologia', sortable: false},
        {key: 'installation_type', label: 'Tipo de instalacion', sortable: false},
        {key: 'date_start', label: 'Fecha inicio explotación', sortable: false},
        {key: 'date_end', label: 'Fecha fin explotación', sortable: false},
        {key: 'period_description', label: 'Duración', sortable: false},
        {key: 'maintainer', label: 'Mantenedor', sortable: false},
        {key: 'opsva_territory', label: 'Territorio_OPSVA', sortable: false},
        {key: 'client_type', label: 'Tipo cliente', sortable: false},
        {key: 'client_contact_person', label: 'Contacto', sortable: false},
        {key: 'client_phone', label: 'Teléfono', sortable: false},
        {key: 'client_email', label: 'Email', sortable: false},
        {key: 'company', label: 'Empresa', sortable: false},
      ],
      showLoading: false,
      helpers,

      figures: [
        { 'id' : 1, 'name': 'MANTENEDOR' },
        { 'id' : 2, 'name': 'PROPIETARIO' },
        { 'id' : 3, 'name': 'TITULAR' }
      ],
    }
  },
  computed: {
    ...mapState('childrenInstallation', ['children_installations']),
  },
  created() {
    this.getInstallations()
  },
  methods: {
    can(permission) {
      return this.$store.getters.can(permission)
    },
    formatDate(date) {
      const dateFormated = new Date(date)

      return dateFormated.toLocaleDateString("es-ES")
    },
    getInstallations() {
      this.showLoading = true

      this.$store.dispatch('childrenInstallation/getAllListChildrenInstallations', {
        relations: ['masterInstallation', 'masterInstallation.company', 'masterInstallation.responsible',
          'installationType', 'masterInstallation.opsvaTerritory', 'masterInstallation.provinceCountry',
          'masterInstallation.clientType', 'providerContractEE', 'typology'],
      })
          .then(response => {
            this.showLoading = false

          })
          .catch(error => {
            this.showLoading = false
            console.log(error)
          })
    },
    async exportToExcel() {
      const workbook = new ExcelJS.Workbook()
      const worksheet = workbook.addWorksheet('InstalacionesHijasGEI')

      const headers = [
        'CIF',
        'CODIGO_GEI_1',
        'InstalaciónActiva',
        'InstalaciónBorrador',
        'Referencia',
        'Responsable',
        'Figura RD171',
        'Pais',
        'Dirección Gei',
        'Provincia/Zona',
        'Población',
        'Tipología',
        'Tipo de instalacion',
        'Fecha inicio explotación',
        'Fecha fin explotación',
        'Duración',
        'Mantenedor',
        'Territorio_OPSVA',
        'Tipo cliente',
        'Contacto',
        'Teléfono',
        'Email',
        'Empresa'
      ]

      worksheet.addRow(headers)

      this.children_installations.forEach(detail => {
        worksheet.addRow([
          detail.master_installation.client_cif,
          detail.gei_code,
          detail.active_installation === 1 ? 'Si' : 'No',
          detail.draft === 1 ? 'Si' : 'No',
          detail.master_installation.client,
          detail.master_installation.responsible ? detail.master_installation.responsible.name : null,
          this.figures[detail.master_installation.figure_id - 1] ? this.figures[detail.master_installation.figure_id - 1].name : null,
          detail.master_installation.province_country ? detail.master_installation.province_country.country : null,
          detail.master_installation.address,
          detail.master_installation.province_country ? detail.master_installation.province_country.province : null,
          detail.master_installation.city,
          detail.typology ? detail.typology.name : null,
          detail.installation_type ? detail.installation_type.type : null,
          detail.start_date ? helpers.formatDate(detail.start_date) : null,
          detail.end_date ? helpers.formatDate(detail.end_date) : null,
          detail.period_description,
          detail.provider_contract_e_e[0] ?
              (detail.provider_contract_e_e[0].provider ? detail.provider_contract_e_e[0].provider.provider : null)
              : null,
          detail.master_installation.opsva_territory ? detail.master_installation.opsva_territory.name : null,
          detail.master_installation.client_type ? detail.master_installation.client_type.type : null,
          detail.master_installation.client_contact_person,
          detail.master_installation.client_phone,
          detail.master_installation.client_email,
          detail.master_installation.company ? detail.master_installation.company.name : null
        ])
      })

      // Generar el archivo Excel
      const buffer = await workbook.xlsx.writeBuffer()

      // Crear un Blob para la descarga
      const blob = new Blob([buffer], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'})

      // Crear una URL para el Blob
      const url = window.URL.createObjectURL(blob)

      // Crear un enlace de descarga y hacer clic en él
      const a = document.createElement('a')
      a.href = url
      a.download = 'instalacionesHijasGEI.xlsx'
      a.click()

      // Liberar recursos
      window.URL.revokeObjectURL(url)
    },
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>